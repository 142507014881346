var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header-top-dashboard',{attrs:{"neutral":""}}),_c('v-container',{staticClass:"pt-0 px-6 mt-n16 container-general",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticClass:"v-card-grey mb-3 px-0  v-card v-sheet theme--light elevation-0"},[_c('v-card-title',{staticClass:"pt-2 pb-0 mb-10 card-title-bloque"},[_c('p',{staticClass:"tit_card"},[_vm._v(" "+_vm._s(_vm.$t('IPs verificadas'))+" ")])]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('validation-observer',{ref:"ipForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('validation-provider',{attrs:{"name":_vm.$t('Nombre del archivo'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('IP permitida'),"hint":_vm.$t('Ejemplo') + ' 192.168.0.1',"filled":"","color":"light-blue darken-2","background-color":"blue-grey lighten-5"},model:{value:(_vm.allowedIp.ip),callback:function ($$v) {_vm.$set(_vm.allowedIp, "ip", $$v)},expression:"allowedIp.ip"}})]}}],null,true)}),_c('v-select',{attrs:{"label":_vm.$t('Subred'),"items":_vm.subNets,"item-value":'value',"item-text":'text',"filled":"","color":"light-blue darken-2","background-color":"blue-grey lighten-5"},model:{value:(_vm.allowedIp.mask),callback:function ($$v) {_vm.$set(_vm.allowedIp, "mask", $$v)},expression:"allowedIp.mask"}}),_c('v-btn',{staticClass:"theme-secondary-btn text-white text-transform-none px-5 fw300 float-right",attrs:{"disabled":invalid,"elevation":"0","large":"","rounded":""},on:{"click":_vm.saveIp}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" fas fa-save ")]),_vm._v(" "+_vm._s(_vm.$t('Guardar'))+" ")],1)]}}])})],1)],1)],1)],1)],1),_c('v-col',{staticClass:" ",attrs:{"cols":"12","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('v-card',{staticClass:"v-card-grey-dark mb-3 px-0  v-card v-sheet theme--light elevation-0"},[_c('v-card-title',{staticClass:"pt-2 pb-0 mb-0 card-title-bloque "},[_c('p',{staticClass:"tit_card"},[_vm._v(" "+_vm._s(_vm.$t('IPs activas'))+" ")])]),_c('v-card-text',{staticClass:"nocard"},[_c('v-data-table',{staticClass:"table thead-light",attrs:{"footer-props":{'items-per-page-options': [5, 10, 15, 25], 'disable-pagination': true, 'items-per-page-text': _vm.$t('Items por página') },"mobile-breakpoint":"0","headers":_vm.headers,"items":_vm.allowedIps,"items-per-page":5,"page":_vm.page,"dense":"","no-data-text":_vm.$t('No hay resultados') + '.'},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"pa-5",attrs:{"align":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn-toggle',[_c('v-btn',_vm._g(_vm._b({staticClass:"btns_table btns_table_eliminar",attrs:{"color":"white","elevation":"0"},on:{"click":function($event){return _vm.removeIp(item)}}},'v-btn',attrs,false),on),[_c('i',{staticClass:"fas fa-trash"})])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Eliminar')))])])],1)]}}])})],1),_c('v-card-actions',{staticClass:"py-0 my-0 mt-lg-n13"},[_c('v-row',[_c('v-col',{staticClass:"ml-auto d-flex justify-end",attrs:{"cols":"6"}},[_c('v-pagination',{staticClass:"pagination",attrs:{"prev-icon":"fa fa-angle-left","next-icon":"fa fa-angle-right","color":"#0094de","length":_vm.pageCount,"circle":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }