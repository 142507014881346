class ThemeLoader {
  themes = [
    {
      host: 'localhost',
      name: 'main-theme',
    },
    {
      host: 'orange',
      name: 'test-theme',
    },
  ]

  loadThemeByHostName () {
    this.removeThemes()
    const currentUrl = window.location.href
    const url = new URL(currentUrl)
    const hostName = url.hostname
    const theme = this.themes.find(theme => theme.host === hostName)
    if (theme) {
      document.body.classList.add(theme.name)
    }
  }

  removeThemes () {
    this.themes.forEach(theme => {
        document.body.classList.remove(theme.name)
    })
  }

  getUserWhiteLabelBrandInfo () {
    const user = JSON.parse(localStorage.getItem('user'))
    return user.whitelabelBrand
  }

  loadThemeFromUser () {
    if (!JSON.parse(localStorage.getItem('user'))) {
      console.log('no user to load theme')
      return
    }

    this.removeThemes()

    const whitelabelBrandInfo = this.getUserWhiteLabelBrandInfo()
    const theme = this.themes.find(theme => theme.host === whitelabelBrandInfo.host)
    console.log(theme)
    if (theme) {
      document.body.classList.add(theme.name)
    }
  }

  getLogoResource (logoFileName) {
    const images = require.context('@/assets/img/logos', false, /\.(png|jpe?g|svg)$/)
    return images(`./${logoFileName}`)
  }
}

const themeLoader = new ThemeLoader()

export default themeLoader
