import EventBus from '@/util/EventBus.js'
import VoicePreview from '@/components/Template/Voice/VoicePreview/VoicePreview.vue'
import downloadObject from '@/util/DownloadObject'
import MultimediaService from '@/services/multimedia.service'
import { WhatsappTemplateStatus } from '@/classes/WhatsappTemplateStatus'
import { LandingFormats } from '../../Common/Formats/LandingFormats'
import WhatsappTemplateService from '@/services/whatsapp-template.service'

export default {
  name: 'ViewGrid',
  components: {
    VoicePreview,
  },
  props: {
    multimediaDirectories: { type: Object, require: true },
    multimediaPreview: { type: Object, required: true },
    fileQuota: { type: Object, require: true },
    currentDirectory: { type: Object, require: true },
    openGlobal: { type: Boolean, require: true },
  },
  data () {
    return {
      currentItem: null,
      badgesWhatsapp: {
        show: false,
        color: '',
        messages: '',
      },
    }
  },
  computed: {
    dataForGrid: {
      // getter
      get: function () {
        const directories = this.setDirectories(this.currentDirectory)
        const files = this.setFiles(this.currentDirectory)
        return directories.concat(files)
      },
      // setter
      set: function (currentDirectory) {
        const data = []
        for (const directory in currentDirectory.directories) {
          data.push({
            name: directory,
            path: currentDirectory.directories[directory].path,
            type: 'directory',
            fileType: currentDirectory.directories[directory].directoryType,
            fileFormat: currentDirectory.directories[directory].fileFormat,
            size: currentDirectory.directories[directory].size,
            date: currentDirectory.directories[directory].date,
            directories: currentDirectory.directories[directory].directories,
          })
        }
        return data
      },
    },
    rootDirectory: function () {
      return this.currentDirectory.path.split('/')[0]
    },
    initialDirectory: function () {
      return this.currentDirectory.initialPath
    },
  },
  methods: {
    setDirectories (currentDirectory) {
      const data = []
      for (const directory in currentDirectory.directories) {
        data.push({
          name: directory,
          path: currentDirectory.directories[directory].path,
          type: 'directory',
          fileType: currentDirectory.directories[directory].directoryType,
          size: currentDirectory.directories[directory].size,
          date: currentDirectory.directories[directory].date,
          directories: currentDirectory.directories[directory].directories,
          hasEditTemplatePermission: this.$permissions.hasPermission('edit-templates') && ['landings', 'formularios', 'voz', 'whatsapp'].includes(currentDirectory.directories[directory].directoryType),
          hasEditFilePermission: this.$permissions.hasPermission('edit-files') && ['imagenes', 'csv', 'audios', 'videos', 'adjuntos'].includes(currentDirectory.directories[directory].directoryType),
          hasDeleteTemplatePermission: this.$permissions.hasPermission('delete-templates') && ['landings', 'formularios', 'voz', 'whatsapp'].includes(currentDirectory.directories[directory].directoryType),
          hasDeleteFilePermission: this.$permissions.hasPermission('delete-files') && ['imagenes', 'csv', 'audios', 'videos', 'adjuntos'].includes(currentDirectory.directories[directory].directoryType),
        })
      }
      return data
    },
    setFiles (currentDirectory) {
      const data = []
      const user = JSON.parse(localStorage.getItem('user'))
      for (const file in currentDirectory.files) {
        data.push({
          name: currentDirectory.files[file].name,
          nameTemplate: currentDirectory.files[file].nameTemplate,
          path: currentDirectory.files[file].path,
          type: 'file',
          extension: currentDirectory.files[file].extension,
          size: currentDirectory.files[file].size,
          date: currentDirectory.files[file].date,
          url: currentDirectory.files[file].url + '&token=' + user.token,
          fileType: currentDirectory.files[file].fileType,
          fileFormat: currentDirectory.files[file].fileFormat,
          csv: currentDirectory.files[file].csv,
          fileId: currentDirectory.files[file].fileId,
          preview: currentDirectory.files[file].preview,
          hasEditTemplatePermission: this.$permissions.hasPermission('edit-templates') && ['landings', 'formularios', 'voz', 'whatsapp'].includes(currentDirectory.files[file].fileType),
          hasEditFilePermission: this.$permissions.hasPermission('edit-files') && ['imagenes', 'csv', 'audios', 'videos', 'adjuntos'].includes(currentDirectory.files[file].fileType),
          hasDeleteTemplatePermission: this.$permissions.hasPermission('delete-templates') && ['landings', 'formularios', 'voz', 'whatsapp'].includes(currentDirectory.files[file].fileType),
          hasDeleteFilePermission: this.$permissions.hasPermission('delete-files') && ['imagenes', 'csv', 'audios', 'videos', 'adjuntos'].includes(currentDirectory.files[file].fileType),
        })
      }
      this.currentItem = null
      return data
    },
    selectIemCheckbox (item, index) {
      this.multimediaPreview.reset()
      const items = this.multimediaDirectories.selectedMultiple.items

      if (items.length === 1) {
        this.currentItem = index
        EventBus.$emit('selectFileMultimedia', item)
        EventBus.$emit('reloadFilePreview')
      }

      if (items.length > 1) {
        this.currentItem = null
        this.multimediaPreview.reset()
      }
    },
    selectItem (item, index) {
      this.multimediaPreview.reset()
      this.currentItem = index
      EventBus.$emit('selectFileMultimedia', item)
      EventBus.$emit('reloadFilePreview')
      this.multimediaDirectories.resetDeleteSelectedMultiple()
      this.multimediaDirectories.selectedMultiple.items.push(item)
    },
    // eslint-disable-next-line complexity
    selectItemDoubleClick (item, index) {
      this.multimediaPreview.reset()
      this.currentItem = index
      if (item.type === 'directory') {
        if (this.currentDirectory.path === 'papelera') {
          this.multimediaDirectories.hideFixedMultimedia()
        }
        const split = item.path.split('/')
        let directory = this.multimediaDirectories.directories[split[0]]
        for (let i = 1; i < split.length; i++) {
          directory = directory.directories[split[i]]
        }
        this.multimediaPreview.reset()
        this.currentDirectory.loadData(directory)
        this.multimediaDirectories.resetDeleteSelectedMultiple()
      } else if (item.fileType === 'landings') {
        if (this.openGlobal) {
          this.$router.push({ path: '/template/landing/create', query: { path: item.path + '/' + item.name } })
        } else {
          this.selectFile(item)
        }
        this.$emit('close')
      } else if (item.fileType === 'formularios') {
        if (this.openGlobal) {
          this.$router.push({ path: '/tools/forms/editor', query: { id: item.fileId } })
        } else {
          this.selectFile(item)
        }
        this.$emit('close')
      } else if (this.rootDirectory === 'voz' && item.type === 'file' && this.openGlobal) {
        this.$router.push({ path: '/template/voice', query: { path: item.path + '/' + item.name } })
        this.$emit('close')
      } else if (item.fileType === 'adjuntos' && item.type === 'file' && !this.openGlobal) {
        this.selectFile(item)
        this.$emit('close')
      }
    },
    selectFile (item) {
      const params = {
        path: item.path,
        filename: item.name,
      }
      MultimediaService.selectedFile(params).then(
        (response) => {
          this.$emit('close')
          EventBus.$emit(
            'multimediaSelected',
            response.url,
            response.type,
            response.fileId,
            item.nameTemplate,
            response.fileInfo,
          )
        },
        () => { },
      )
    },
    createDirectory (item) {
      this.multimediaDirectories.createDirectory.path = item.path
      this.multimediaDirectories.createDirectory.show = true
    },
    removeDirectory (item) {
      this.multimediaDirectories.deletedDirectory.path = item.path
      this.multimediaDirectories.deletedDirectory.newPath = 'papelera'
      this.multimediaDirectories.deletedDirectory.type = item.type
      this.multimediaDirectories.deletedDirectory.name = item.name
      this.multimediaDirectories.deletedDirectory.show = true
    },
    removeFile (item) {
      if (this.currentDirectory.path === 'whatsapp') {
        WhatsappTemplateService.isBeingUsed(item.fileId)
        .then((response) => {
          if (response.isBeingUsed) {
            EventBus.$emit('showAlert', 'danger', this.$t('No puedes borrar esta plantilla porque está siendo utilizada en una campaña.'))
          } else {
            this.confirmRemoveFile(item)
          }
        })
      } else {
        this.confirmRemoveFile(item)
      }
    },
    confirmRemoveFile (item) {
      this.multimediaDirectories.deletedFile.path = item.path
      this.multimediaDirectories.deletedFile.newPath = 'papelera'
      this.multimediaDirectories.deletedFile.type = item.type
      this.multimediaDirectories.deletedFile.name = item.name
      this.multimediaDirectories.deletedFile.fileId = item.fileId
      this.multimediaDirectories.deletedFile.show = true
    },
    moveFile (item) {
      this.multimediaDirectories.move.path = this.currentDirectory.path
      this.multimediaDirectories.move.type = item.type
      this.multimediaDirectories.move.name = item.name
      this.multimediaDirectories.move.fileType = item.fileType
      this.multimediaDirectories.move.fileId = item.fileId
      this.multimediaDirectories.move.show = true
    },
    renameDirectory (item) {
      this.multimediaDirectories.rename.path = this.currentDirectory.path
      this.multimediaDirectories.rename.type = item.type
      this.multimediaDirectories.rename.name = item.name
      this.multimediaDirectories.rename.newName = item.name
      this.multimediaDirectories.rename.fileType = item.fileType
      this.multimediaDirectories.rename.show = true
    },
    duplicateItem (item) {
      switch (item.fileType) {
        case 'landings':
          this.$router.push({
            path: '/template/landing/create',
            query: {
              path: item.path + '/' + item.name,
              duplicate: true,
            },
          })
          break
        case 'formularios':
          this.$router.push({
            path: '/tools/forms/editor',
            query: {
              id: item.fileId,
              duplicate: true,
            },
          })
          break
        case 'voz':
          this.$router.push({
            path: '/template/voice',
            query: {
              path: item.path + '/' + item.name,
              duplicate: true,
            },
          })
          break
        case 'whatsapp':
          this.$router.push({
            path: '/template/whatsapp/create',
            query: {
              duplicateId: item.fileId,
              type: 'basic',
            },
          })
          break
      }
      this.$emit('close')
    },
    // eslint-disable-next-line complexity
    colGrid (rootDirectory) {
      if (['buscar'].indexOf(rootDirectory.toLowerCase()) >= 0 && (
        this.currentDirectory.initialPath.split('/')[0] === 'imagenes' ||
        this.currentDirectory.initialPath.split('/')[0] === 'videos' ||
        this.currentDirectory.initialPath.split('/')[0] === 'whatsapp' ||
        this.currentDirectory.initialPath.split('/')[0] === 'audios' ||
        this.currentDirectory.initialPath.split('/')[0] === 'adjuntos')
      ) {
        return 'col-md-3'
      }
      if (['buscar'].indexOf(rootDirectory.toLowerCase()) >= 0 && (
        this.currentDirectory.initialPath.split('/')[0] === 'landings' ||
        this.currentDirectory.initialPath.split('/')[0] === 'formularios')
      ) {
        return 'col-md-4'
      }
      if (['csv', 'voz', 'papelera', 'buscar'].indexOf(rootDirectory.toLowerCase()) >= 0) {
        return 'col-md-2'
      } else if (['landings', 'formularios'].indexOf(rootDirectory.toLowerCase()) >= 0) {
        return 'col-md-4'
      } else {
        return 'col-md-3'
      }
    },
    selectItemByName (name) {
      const item = this.dataForGrid.find((item) => item.name === name) || -1
      const index = this.dataForGrid.indexOf(item)
      this.selectItem(item, index)
    },
    canDownload (item) {
      const downloadFileTypes = ['imagenes', 'csv', 'audios', 'adjuntos']
      return item.type === 'file' && downloadFileTypes.indexOf(item.fileType) >= 0
    },
    downloadFile (item) {
      console.log(item)
      downloadObject.download(item.url, item.name)
    },
    selectWhatsapp (item) {
      this.$router.push({ path: '/template/whatsapp/create', query: { templateId: item.fileId, type: 'basic' } })
      this.$emit('close')
    },
    selectEditLanding (item) {
      this.$router.push({ path: '/template/landing/create', query: { path: item.path + '/' + item.name } })
      this.$emit('close')
    },
    selectEditForm (item) {
      this.$router.push({ path: '/tools/forms/editor', query: { id: item.fileId } })
      this.$emit('close')
    },
    selectEditCall (item) {
      this.$router.push({ path: '/template/voice', query: { path: item.path + '/' + item.name } })
      this.$emit('close')
    },
    messageBadgesWhatsapp (item) {
      switch (item.preview?.template_whatsapp_status_id) {
        case WhatsappTemplateStatus.APPROVED:
          return this.$t('Aprobada')
        case WhatsappTemplateStatus.PENDING:
          return this.$t('Pendiente de aprobación')
        case WhatsappTemplateStatus.DRAFT:
          return this.$t('Borrador')
        case WhatsappTemplateStatus.SENT_TO_VALIDATE:
          return this.$t('En revisión')
        case WhatsappTemplateStatus.PAUSED:
          return this.$t('Pausada')
        case WhatsappTemplateStatus.INCORRECT_FORMAT:
          return this.$t('Formato incorrecto')
        default:
          return this.$t('Rechazada')
      }
    },
    colorBadgesWhatsapp (item) {
      switch (item.preview?.template_whatsapp_status_id) {
        case WhatsappTemplateStatus.APPROVED:
          return 'green'
        case WhatsappTemplateStatus.PENDING:
          return 'orange'
        case WhatsappTemplateStatus.DRAFT:
          return '#05ADE6'
        case WhatsappTemplateStatus.SENT_TO_VALIDATE:
          return '#FFC107'
        case WhatsappTemplateStatus.PAUSED:
          return 'gray'
        case WhatsappTemplateStatus.INCORRECT_FORMAT:
          return 'coral'
        default:
          return 'red'
      }
    },
    isTemplateWhatsapp (item) {
      let status = false
      if (item.type === 'file' && item.fileType === 'whatsapp') {
        if (
          [
            WhatsappTemplateStatus.DRAFT,
            WhatsappTemplateStatus.REJECTED,
            WhatsappTemplateStatus.PAUSED,
            WhatsappTemplateStatus.APPROVED,
            WhatsappTemplateStatus.PENDING,
            WhatsappTemplateStatus.INCORRECT_FORMAT,
          ].indexOf(item.preview?.template_whatsapp_status_id) >= 0
        ) {
          status = true
        }
      }

      return status
    },
    isTemplateWhatsappDraft (item) {
      let status = false
      if (item.type === 'file' && item.fileType === 'whatsapp') {
        if (
          [
            WhatsappTemplateStatus.DRAFT,
            WhatsappTemplateStatus.INCORRECT_FORMAT,
            WhatsappTemplateStatus.REJECTED,
          ].indexOf(item.preview?.template_whatsapp_status_id) >= 0
        ) {
          status = true
        }
      }

      return status
    },
    categoryName (item) {
      return item.preview?.template_whatsapp_category?.name
    },
    applyFilters (data) {
      let filteredData = data
      const filters = this.multimediaDirectories.filter
      if (this.rootDirectory === 'landings') {
        if (filters.landingFormat !== null) {
          filteredData = filteredData.filter(e => +e.fileFormat === +filters.landingFormat)
        }
      }

      if (this.rootDirectory === 'whatsapp') {
        if (filters.whatsappTemplateStatus !== null) {
          filteredData = filteredData.filter(e => +e.preview?.template_whatsapp_status_id === +filters.whatsappTemplateStatus)
        }
        if (filters.whatsappTemplateCategory !== null) {
          filteredData = filteredData.filter(e => +e.preview?.template_whatsapp_category_id === +filters.whatsappTemplateCategory)
        }
        if (filters.whatsappTemplateLanguage !== null) {
          filteredData = filteredData.filter(e => +e.preview?.template_whatsapp_language_id === +filters.whatsappTemplateLanguage)
        }
        if (filters.whatsappTemplateWabaId !== null) {
          filteredData = filteredData.filter(e => +e.preview?.whatsapp_waba_id === +filters.whatsappTemplateWabaId)
        }
      }
      return filteredData
    },
    formatName (item) {
      if (item.fileFormat === null || item.fileFormat === undefined) {
        return ''
      }

      switch (item.fileType) {
        case 'landings':
          return LandingFormats.getFormatName(item.fileFormat)
      }
    },
    formatColor (item) {
      if (item.fileFormat === null || item.fileFormat === undefined) {
        return ''
      }

      switch (item.fileType) {
        case 'landings':
          return LandingFormats.getFormatColor(item.fileFormat)
      }
    },
  },
  mounted () {
    EventBus.$on('selectItem', ({ name }) => this.selectItemByName(name))
    EventBus.$on('ViewGrid:clearSelectedItem', () => {
      this.currentItem = null
    })
  },
  watch: {
    dataForGrid: {
      handler (newVal) {
        console.log(newVal)
      },
      deep: true, // Esto permite observar cambios profundos en el objeto
    },
  },
  beforeDestroy () {
    EventBus.$off('selectItem', ({ name }) => this.selectItemByName(name))
  },
}
