import MarginProperty from '../../common/margin-property/MarginProperty.vue.js'
import Editor from '@tinymce/tinymce-vue'
import UnsuscribeService from '@/services/unsuscribe.service.js'
import AccountService from '@/services/account.service.js'
import { Languages } from '@/classes/Languages.js'
import $t from '@/util/Translator'
import TextEditor from '@/components/TextEditor/TextEditor.vue'
import { CancelSubscription } from '@/components/Template/LandingEditor/classes/CancelSubscription.js'
import EventBus from '@/util/EventBus.js'

export default {
  name: 'SimpleCancelSubscriptionProperties',
  components: {
    MarginProperty,
    Editor,
    TextEditor,
  },
  props: { landing: { type: Object, required: true } },
  data: function () {
    return {
      languages: Languages.languages.map(l => ({ value: l.iso, text: l.tooltip })),
      selectedLanguage: null,
      companies: [],
      selectedCompany: null,
      landingUnsubscribePages: [],
      renderEditor: false,
      user: this.$store.getters['auth/getUser'],
      component: new CancelSubscription(this.user?.isCountryEu || JSON.parse(localStorage.getItem('user')).isCountryEu),
    }
  },
  watch: {},
  methods: {
    textChanged () {
      const parser = new DOMParser()
      const htmlDoc = parser.parseFromString(this.landing.htmlContent, 'text/html')
      const container = htmlDoc.getElementById('unsubscribe-container')

      if (![null, undefined].includes(container)) {
        container.innerHTML = this.component.tinymce
        const s = new XMLSerializer()
        const htmlDocString = s.serializeToString(htmlDoc)
        this.$emit('updatedHtmlCode', htmlDocString)
      }
    },
    async getProfile () {
      let profile = null
      await AccountService.getBilling()
      .then(
        (response) => {
          profile = response.billingProfiles[0]
          this.companies.push({
            text: profile.company_name,
            value: `${profile.company_name} ${profile.CIF} - ${profile.address} ${profile.CP} ${profile.city}`,
          })
          AccountService.getAdditionalCompanies()
            .then((companies) => {
              companies.forEach(company => {
                this.companies.push({
                  text: company.company_name,
                  value: `${company.company_name} ${company.CIF} - ${company.address} ${company.CP} ${company.city}`,
                })
              })
            })
        },
        () => {},
      )
      return profile
    },
    updatedHtmlContent () {
      const parser = new DOMParser()
      const htmlDoc = parser.parseFromString(this.landing.htmlContent, 'text/html')
      // const container = htmlDoc.getElementById('unsubscribe-container')

      htmlDoc.body.innerHTML = htmlDoc.body.innerHTML + '<div id="unsubscribe-container">' + this.component.tinymce + '</div>'
      const s = new XMLSerializer()
      const htmlDocString = s.serializeToString(htmlDoc)
      this.$emit('updatedHtmlCode', htmlDocString)

      // if (container === null || container === undefined) {
      //   htmlDoc.body.innerHTML = htmlDoc.body.innerHTML + '<div id="unsubscribe-container">' + this.component.tinymce + '</div>'
      //   const s = new XMLSerializer()
      //   const htmlDocString = s.serializeToString(htmlDoc)
      //   this.$emit('updatedHtmlCode', htmlDocString)
      // } else {
      //   const link = htmlDoc.getElementById('unsusbcribeLink')
      //   if (link === null || link === undefined) {
      //     container.innerHTML = container.innerHTML + `<p style="text-align: ${this.component.alignment};"><a
      //       id="unsusbcribeLink"
      //       style="color: ${this.component.urlColor};"
      //       class="temp-disable-link-action"
      //       href="${this.component.redirectTo1}"
      //       target="_blank"
      //       contenteditable="false"
      //     >${this.component.text1}</a>
      //   </p>`
      //     const s = new XMLSerializer()
      //     const htmlDocString = s.serializeToString(htmlDoc)
      //     this.$emit('updatedHtmlCode', htmlDocString)
      //   }
      // }
    },
    changeUrlAndLanguage () {
      const parser = new DOMParser()
      const htmlDoc = parser.parseFromString(this.component.tinymce, 'text/html')
      const link = htmlDoc.getElementById('unsusbcribeLink')

      if (link.href.search(this.component.redirectTo1) >= 0) {
        link.textContent = $t('Darse de baja', this.component.language)
        link.href = this.component.redirectTo1
        this.component.tinymce = htmlDoc.body.innerHTML
        this.$refs.cancelSubsEditor.resetEditorContent()
      }
    },
    changeCompany () {
      this.component.secondaryText = this.component.company
      // console.log('this.component.company:', this.component.company)
      const parser = new DOMParser()
      const htmlDoc = parser.parseFromString(this.component.tinymce, 'text/html')
      // console.log('htmlDoc:', htmlDoc)
      const companyData = htmlDoc.querySelector('p:nth-child(2)')
      if (companyData) {
        companyData.innerHTML = this.component.secondaryText
        // console.log('companyData1:', companyData.innerHTML)
        this.component.tinymce = htmlDoc.body.innerHTML
        // console.log('tinymce:', this.component.tinymce)
        this.$refs.cancelSubsEditor.resetEditorContent()
      }
    },
    getUnsubscribePages () {
      this.landingUnsubscribePages = []
      UnsuscribeService.getPagesOptions({ type: 'mailing' }).then((response) => {
        response.options.map((page) => {
          this.$set(this, 'landingUnsubscribePages', [
            ...this.landingUnsubscribePages,
            {
              value: `[unsubscribe_link_${page.id}]`,
              text: page.name,
            },
          ])
        })
      })
    },
    changeUnsubscribePage () {
      this.changeUrlAndLanguage()
    },
    isSelected (unsubscribePage) {
      if (unsubscribePage.value === this.component.redirectTo1) {
        return true
      }
      return false
    },
  },
  computed: {
    isDefaultSelected () {
      return this.component.redirectTo1 === '[unsubscribe_link]'
    },
  },
  created: async function () {
    console.log('SimpleCancelSubscriptionProperties@created')
    this.component = new CancelSubscription(
      this.user?.isCountryEu || JSON.parse(localStorage.getItem('user')).isCountryEu,
    )
    // this.component = this.landing.structure.containers[this.containerIndex].slots[this.slotIndex][0]
    // console.log('this.landing:', this.landing)
    if (this.component.tinymce === '') {
      const profile = await this.getProfile()
      // console.log('this.component:', this.component)
      if (!this.component.company) {
        this.component.company = this.companies[0].value
      }

      if (profile) {
        this.component.secondaryText = `${profile.company_name} ${profile.CIF} - ${profile.address} ${profile.CP} ${profile.city}`
      }

      // this.component.tinymce = `<p style="text-align: ${this.component.alignment};font-size: ${this.component.mainTextFontSize}px;font-family: ${this.component.mainTextFont};color: ${this.component.mainTextColor};"
      // > ${this.component.mainText}</p><p style="text-align: ${this.component.alignment};font-size: ${this.component.secondaryTextSize}px;font-family: ${this.component.secondaryTextFont};color: ${this.component.secondaryTextColor};font-family: Helvetica,Arial,sans-serif;
      // ">${this.component.secondaryText}</p><p style="text-align: ${this.component.alignment};">
      // if (!this.user.avoidUnsubscribeLink) {
      //   this.component.tinymce += `<a
      //       id="unsusbcribeLink"
      //       style="color: ${this.component.urlColor};"
      //       class="temp-disable-link-action"
      //       href="${this.component.redirectTo1}"
      //       target="_blank"
      //       contenteditable="false"
      //     >${this.component.text1}</a>`
      // }

      // this.component.tinymce += '</p>'

      this.component.tinymce = `<p style="text-align: ${this.component.alignment};font-size: ${this.component.mainTextFontSize}px;font-family: ${this.component.mainTextFont};color: ${this.component.mainTextColor};"
      > ${this.component.mainText}</p>`

      this.getUnsubscribePages()
    }
    this.renderEditor = true
  },
  mounted: function () {
    const that = this
    const elements = document.querySelectorAll('.tox .tox-edit-area__iframe')
    elements.forEach(function (elemen) {
      elemen.setAttribute('style', 'background-color: ' + that.landing.structure.containers[that.containerIndex].backgroundColor + ' !important;')
    })
    this.getUnsubscribePages()

    EventBus.$on('updateSelectedCompany', () => {
      setTimeout(() => {
        if (this.landing) {
          const parser = new DOMParser()
          const htmlDoc = parser.parseFromString(this.landing.htmlContent, 'text/html')
          if (htmlDoc) {
            const companyData = htmlDoc.querySelector('#unsubscribe-container p:nth-child(2)')
            // eslint-disable-next-line max-depth
            if (companyData) {
              // eslint-disable-next-line max-depth
              if (this.component.secondaryText !== companyData.innerHTML.trim()) {
                this.component.secondaryText = companyData.innerHTML.trim()
                this.component.company = this.component.secondaryText
                this.changeCompany()
              }
            }
          }
        }
      })
    })
  },
}
