import CreateCampaignModal from '@/components/Layout/Header/modals/create-campaign-modal/CreateCampaignModal.vue'
import getEnv from '@/util/env'
import EventBus from '@/util/EventBus'
import AccountService from '@/services/account.service.js'
import NewsService from '@/services/news.service.js'
import { mapState } from 'vuex'
import { Languages } from '@/classes/Languages'
import { ValidatorLocalize } from '@/util/ValidatorLocalize'
import DeveloperService from '@/services/developer.service'
import WhatsappTemplateService from '@/services/whatsapp-template.service.js'
import { popupCenter } from '@/util/Popup.js'
import { Subscriptions } from '@/classes/helpers/Subscriptions'
import RoutesService from '@/services/whatsapp/routes.service'
import { Roles } from '../../../classes/Roles'
import TaskListService from '@/services/assistant/task-list.service.js'

import ThemeLoader from '@/util/theme-loader'
const VUE_APP_OLD_DASHBOARD_URL = getEnv('VUE_APP_OLD_DASHBOARD_URL')

export default {
  name: 'app-bar',
  components: {
    CreateCampaignModal,
  },
  props: {
    background: String,
    hasBg: Boolean,
    linkColor: String,
    toggleActive: String,
  },
  data () {
    return {
      userLanguage: null,
      languages: Languages.get(),
      urlWhiteBook: getEnv('VUE_APP_API_URL') + 'docs/help/LIBRO_BLANCO_360NRS.pdf',
      urlRgpd: getEnv('VUE_APP_API_URL') + 'docs/help/RGPD.pdf',
      sectionsBlockeds: JSON.parse(localStorage.getItem('sectionsBlockeds')) || [],
      user: this.$store.getters['auth/getUser'],
      drawer: false,
      togglerActive: false,
      showCreateCampaignModal: false,
      tab: null,
      firstLevelTabs: [
        { id: 0, name: 'sms', label: 'SMS' },
        { id: 1, name: 'mail', label: 'Mail' },
        { id: 2, name: 'voz', label: 'Voz' },
        { id: 3, name: 'whatsapp', label: 'WhatsApp' },
        { id: 4, name: 'rcs', label: 'RCS' },
        { id: 5, name: 'chatbots', label: 'Chatbots' },
        { id: 6, name: 'apis', label: 'Apis' },
        { id: 7, name: 'integrations', label: 'Integraciones' },
        { id: 8, name: 'help', label: 'Ayuda' },
      ],
      routes: {
        campaigns: {
          sms: {
            basic: '/campaign/sms/create?type=basic',
            landing: '/campaign/sms/create?type=basic&landing=true',
            poll: '/campaign/sms/create?type=basic&poll=true',
            meet: '/campaign/sms/create?type=meet',
            birthday: '/campaign/sms/create?type=birthday',
          },
          mailing: {
            basic: '/campaign/mailing/create?type=basic',
            meet: '/campaign/mailing/create?type=meet',
            birthday: '/campaign/mailing/create?type=birthday',
          },
          whatsapp: {
            template: '/template/whatsapp/create',
            shipping: '/campaign/whatsapp/create?type=basic',
          },
          rcs: {
            basic: '/campaign/rcs/create?type=basic',
            meet: '/campaign/rcs/create?type=meet',
            birthday: '/campaign/rcs/create?type=birthday',
          },
        },
      },
      createCampaignModalData: {
        title: '',
        icon: '',
        iconResourceType: 'icon',
        image: '',
        imageLanding: '',
        channel: 'sms',
        description: {
          basic: '',
          automatic: '',
          meet: '',
          birthday: '',
        },
        routes: [],
        show: false,
      },
      dropdown2: [
        {
          name: this.$t('Plugins y módulos'),
          icon: 'fa-puzzle-piece',
          bg: 'bg-gradient-info',
          path: '/developers/plugin-and-integrations',
        },
        {
          name: 'Google verified',
          icon: 'fa-shield-alt',
          bg: 'bg-gradient-warning',
          path: '/developers/verified',
        },
        {
          name: this.$t('Documentación API'),
          icon: 'fa-code',
          bg: 'bg-gradient-success',
          path: 'https://apidocs.360nrs.com/es/#autenticacion',
        },
      ],
      newsDropDown: [],
      newsCounter: 0,
      userPlan: null,
      dltAvailable: [
        'in',
      ],
      balanceAlert: {},
      integrations: [],
      whatsappRoutes: [],
      incompleteTaskCounter: 0,
      logoResourceName: 'monograma-blanco.svg',
    }
  },
  computed:
    mapState({
      logo: state => state.logo,
      balance: state => state.balance,
      displayLanguageSelector: function () {
      return this.userLanguage !== null
    },
    logoSrc () {
      return ThemeLoader.getLogoResource(this.logoResourceName)
    },
    canWhatsapp () {
      return this.$store.getters['auth/getUser'].enable?.wa
    },
    canWebchat () {
      return this.$store.getters['auth/getUser'].enable?.wchat
    },
    headerClass: function () {
      return (this.$route.name === 'HomePage') ? 'complete-header' : 'static-complete-header'
    },
    invoiced () {
      return localStorage.getItem('invoiced')
    },
    apiDocURL () {
      const language = this.$i18n.locale
      if (['es', 'pt'].indexOf(language) >= 0) {
        return `https://apidocs.360nrs.com/${ language }/`
      }
      return 'https://apidocs.360nrs.com/'
    },
    // userLanguageOrDefault () {
    //   return this.userLanguage || Languages.defaultLanguageIso
    // },
    isActivePromotionalPlan () {
      return this.userPlan?.userPlan?.plan_id === 2 &&
             this.userPlan?.userPlan?.fee === 0 &&
             this.userPlan?.userPlan?.balance_mailing > this.userPlan?.userPlan?.limit_mailing
    },
    isDltAvailable () {
      if (
        this.dltAvailable.includes(JSON.parse(localStorage.getItem('user')).isoTld)
      ) {
        return true
      }

      return false
    },
    hasKlaviyo () {
      return this.integrations?.klaviyo?.status
    },
    hasReferrer () {
      return this.user.referrer
    },
    referrerHeaderLogo () {
      return this.user.referrer?.logoHeader
    },
    firstWaba () {
      return this.whatsappRoutes[0]?.waba_id ?? ''
    },
    hasSubscription () {
      return this.$store.getters['auth/getUser'].subscriptions?.whatsapp
    },
    userRoles () {
      return this.$store.getters['auth/getUser'].roles
    },
  }),
  watch: {
    toggleActive (val) {
      this.togglerActive = val
    },
  },
  created () {
    this.getLogo()
    this.getUserBalance()
    this.setBalanceAlert()
    // this.getUserPlan()
    this.getUserLanguage()
    this.getUserCountry()
    this.getNews()
    this.getIntegrations()
    this.getWhatsappRoutes()
  },
  mounted () {
    const firstLevel = this.$route.meta.tab ?? this.getUrlSegments()[1]
    this.tab = this.getTabIdByName(this.firstLevelTabs, firstLevel)

    EventBus.$on('newCampaign', (channel) => {
      this.newCampaign(channel)
    })

    EventBus.$on('newSmsCampaign', () => {
      this.channelSmsData()
      this.showCreateCampaignModal = true
    })

    EventBus.$on('newWhatsappCampaign', () => {
      this.newCampaignWhatsapp()
      // this.showCreateCampaignModal = true
    })
    EventBus.$on('updatePendingTasksCounter', () => {
      this.getCurrentPendingTasks()
    })
    this.getCurrentPendingTasks()

    const whiteLabelBrandInfo = ThemeLoader.getUserWhiteLabelBrandInfo()
    this.logoResourceName = 'logo-' + whiteLabelBrandInfo.hash + '.svg'
  },
  methods: {
    translateRole (role) {
      switch (role) {
        case Roles.ADMIN: return this.$t('Administrador')
        case Roles.MANAGER: return this.$t('Gestor')
        case Roles.AGENT: return this.$t('Agente')
      }
    },
    getCurrentPendingTasks () {
      TaskListService.getCurrentPendingTasks()
      .then((count) => {
        this.incompleteTaskCounter = count
      })
    },
    getWhatsappRoutes () {
      RoutesService.getCurrentUserAllRoutes()
      .then((routes) => {
        this.whatsappRoutes = routes
      })
    },
    viewChatInbox () {
      EventBus.$emit('loadChatWhatsappInbox')
    },
    viewRcsInbox () {
      EventBus.$emit('loadChatWhatsappInbox')
    },
    viewDoc (url, title) {
      popupCenter(url, title, 1000, 600)
    },
    getIntegrations () {
      DeveloperService.getPluginModulesAndIntegrations({ lang: 'en' })
      .then((response) => {
          this.integrations = response.integrations
        },
        () => {},
      )
    },
    goToOldDashboard () {
      AccountService.getAutoLoginToken().then((tokenData) => {
        window.location.href = VUE_APP_OLD_DASHBOARD_URL + 'autologin/' + tokenData.username + '/' + tokenData.token
      })
    },
    async getLogo () {
      try {
        await this.$store.dispatch('logo/getLogo')
      } catch (error) {
        console.error(error)
      }
    },
    async getUserBalance () {
      try {
        await this.$store.dispatch('balance/getBalance')
      } catch (error) {
        console.error(error)
      }
    },
    async getUserPlan () {
      AccountService.getPlan().then((planData) => {
        this.userPlan = planData
      })
    },
    getNews () {
      NewsService.getNews()
      .then(
        (response) => {
          if (response.news) {
            this.newsDropDown = response.news
            this.newsCounter = response.count
          }
        },
        () => {},
      )
    },
    markAllNewsAsRead () {
      NewsService.markAllNewsAsRead()
      .then(
        () => this.getNews(),
        () => {},
      )
    },
    initCreateCampaignModalData: function () {
      return {
        title: '',
        icon: '',
        iconResourceType: 'icon',
        image: '',
        imageLanding: '',
        channel: 'sms',
        description: {
          basic: '',
          automatic: '',
          meet: '',
          birthday: '',
        },
        routes: [],
        show: false,
      }
    },
    showMultimedia () {
      EventBus.$emit('showMultimedia', 'landings', true)
    },
    goTo (site) {
      this.$router.push(site)
    },
    logout () {
      this.$store.dispatch('auth/logout').then(
        () => {
          localStorage.removeItem('invoiced')
          this.$router.push('/login')
        },
        (error) => {
          if (error.response.status === 422) {
            EventBus.$emit('showAlert', 'danger', this.$t('Error al intentar cerrar sesión'))
          }
          EventBus.$emit('showAlert', 'danger', this.$t(error.response.data.error))
        },
      )
    },
    drawerClose () {
      this.togglerActive = !this.togglerActive
      this.$emit('drawer-toggle', true)
    },
    newCampaign (channel) {
      switch (channel) {
        case 'sms':
          this.channelSmsData()
          this.showCreateCampaignModal = true
          break
        case 'mailing':
          this.channelMailingData()
          this.showCreateCampaignModal = true
          break
        case 'whatsapp':
          this.channelWhatsappData()
          break
        case 'rcs':
          this.channelRcsData()
          this.showCreateCampaignModal = true
      }
    },
    newCampaignWhatsapp () {
      WhatsappTemplateService.currentUserhasTemplates().then((response) => {
        if (response.hasTemplates) {
          this.createSenderWhatsapp()
        } else {
          this.channelWhatsappData()
        }
      })
    },
    createSenderWhatsapp () {
      Subscriptions.checkSubscription(
        Subscriptions.WHATSAPP,
        () => {
          this.whatsappRoutes.length
            ? EventBus.$emit('showMultimedia', 'whatsapp', false, ['whatsapp'], 'whatsapp')
            : this.$router.push({ name: 'AssistantPage' }).catch(() => { this.$forceUpdate() })
        },
        () => this.$router.push({ name: 'WhatsappSubscriptionNeeded' }).catch(() => { this.$forceUpdate() }),
      )
    },
    channelSmsData () {
      const localeIso = localStorage.getItem('language') || 'es'
      this.createCampaignModalData = this.initCreateCampaignModalData()
      this.createCampaignModalData.channel = 'sms'
      this.createCampaignModalData.title = this.$t('Nueva campaña SMS').toString()
      this.createCampaignModalData.description.basic = this.$t('Envía o programa una campaña de SMS e impacta al 97% de tu base de datos en menos de 3 minutos').toString() + '.'
      this.createCampaignModalData.description.landing = this.$t('Convierte tus SMS en ventas directas añadiendo en tu mensaje una página de captación').toString() + '.'
      this.createCampaignModalData.description.poll = this.$t('Mide el grado de satisfacción de tus clientes y detecta nuevas necesidades').toString() + '.'
      this.createCampaignModalData.description.automatic = this.$t('Programa tus campañas y deja que 360NRS las envíe por ti').toString() + '.'
      this.createCampaignModalData.description.meet = this.$t('Automatiza tus SMS de recordatorio de citas con base a una fecha').toString() + '.'
      this.createCampaignModalData.description.birthday = this.$t('Automatiza tus SMS de aniversario con base a una fecha').toString() + '.'
      this.createCampaignModalData.icon = 'fa-paper-plane'
      this.createCampaignModalData.image = require('@/assets/img/icons/sms.svg')
      this.createCampaignModalData.helpImage = require('@/assets/img/campaign-examples/' + localeIso + '/basic-sms.png')
      this.createCampaignModalData.imageLanding = require('@/assets/img/icons/rocket-solid.svg')
      this.createCampaignModalData.helpImageLanding = require('@/assets/img/campaign-examples/' + localeIso + '/basic-landing.png')
      this.createCampaignModalData.imagePoll = require('@/assets/img/icons/poll2.svg')
      this.createCampaignModalData.helpImagePoll = require('@/assets/img/campaign-examples/' + localeIso + '/basic-poll.png')
      this.createCampaignModalData.helpImageBirthday = require('@/assets/img/campaign-examples/' + localeIso + '/basic-sms-birthday.png')
      this.createCampaignModalData.helpImageMeet = require('@/assets/img/campaign-examples/' + localeIso + '/basic-sms-meet.png')
      this.createCampaignModalData.iconResourceType = 'image'
      this.createCampaignModalData.routes = this.routes.campaigns.sms
      this.createCampaignModalData.show = true
    },
    channelMailingData () {
      const localeIso = localStorage.getItem('language') || 'es'
      this.createCampaignModalData = this.initCreateCampaignModalData()
      this.createCampaignModalData.channel = 'mailing'
      this.createCampaignModalData.title = this.$t('Nueva campaña Mailing').toString()
      this.createCampaignModalData.description.basic = this.$t('Envia o programa una campaña de Email básica').toString()
      this.createCampaignModalData.description.automatic = this.$t('Automatiza tus Emails con base a una fecha del contacto, cita, aniversario').toString()
      this.createCampaignModalData.description.meet = this.$t('Automatiza tus Emails de recordatorio de citas con base a una fecha').toString()
      this.createCampaignModalData.description.birthday = this.$t('Automatiza tus Emails de aniversario con base a una fecha').toString()
      this.createCampaignModalData.icon = 'fa-at'
      this.createCampaignModalData.image = require('@/assets/img/icons/mail.svg')
      this.createCampaignModalData.helpImageMailBasic = require('@/assets/img/campaign-examples/' + localeIso + '/basic-mail.png')
      this.createCampaignModalData.helpImageMeet = require('@/assets/img/campaign-examples/' + localeIso + '/mail-meet.png')
      this.createCampaignModalData.helpImageBirthday = require('@/assets/img/campaign-examples/' + localeIso + '/mail-birthday.png')
      this.createCampaignModalData.iconResourceType = 'image'
      this.createCampaignModalData.routes = this.routes.campaigns.mailing
      this.createCampaignModalData.show = true
    },
    channelWhatsappData () {
      Subscriptions.checkSubscription(
        Subscriptions.WHATSAPP,
        () => {
          this.whatsappRoutes.length
            ? this.channelWhatsappDataOpen() // this.$router.push({ name: this.$route.name, query: this.$route.query }).catch(() => { this.$forceUpdate() })
            : this.$router.push({ name: 'AssistantPage' }).catch(() => { this.$forceUpdate() })
        },
        () => this.$router.push({ name: 'WhatsappSubscriptionNeeded' }).catch(() => { this.$forceUpdate() }),
      )
    },
    channelWhatsappDataOpen () {
      const localeIso = localStorage.getItem('language') || 'es'
      this.createCampaignModalData = this.initCreateCampaignModalData()
      this.createCampaignModalData.channel = 'whatsapp'
      this.createCampaignModalData.title = this.$t('Nueva plantilla WhatsApp').toString()
      this.createCampaignModalData.description.template = this.$t('Envía o programa una campaña de WhatsApp e impacta al 97% de tu base de datos en menos de 3 minutos').toString() + '.'
      this.createCampaignModalData.description.shipping = this.$t('Envía o programa una campaña de WhatsApp e impacta al 97% de tu base de datos en menos de 3 minutos').toString() + '.'
      this.createCampaignModalData.icon = 'fa-whatsapp'
      this.createCampaignModalData.image = require('@/assets/img/icons/whatsapp.svg')
      this.createCampaignModalData.helpImage = require('@/assets/img/campaign-examples/' + localeIso + '/basic-sms.png')
      this.createCampaignModalData.imageShipping = require('@/assets/img/icons/rocket-solid.svg')
      this.createCampaignModalData.helpImageShipping = require('@/assets/img/campaign-examples/' + localeIso + '/basic-landing.png')
      this.createCampaignModalData.iconResourceType = 'image'
      this.createCampaignModalData.routes = this.routes.campaigns.whatsapp
      this.createCampaignModalData.whatsappRoutes = this.whatsappRoutes
      this.createCampaignModalData.showCreateTemplate = true
      this.createCampaignModalData.show = true
      this.showCreateCampaignModal = true
    },
    channelRcsData () {
      const localeIso = localStorage.getItem('language') || 'es'
      this.createCampaignModalData = this.initCreateCampaignModalData()
      this.createCampaignModalData.channel = 'rcs'
      this.createCampaignModalData.title = this.$t('Nueva campaña RCS').toString()
      this.createCampaignModalData.description.basic = this.$t('Fortalece la confianza de tus clientes con RCS, el 71% de tus usuarios se sientan más seguros al recibir mensajes verificados').toString() + '.'
      this.createCampaignModalData.description.enriched = this.$t('Envía una campaña interactiva con imágenes, botones y más, impactando a tu audiencia de forma dinámica y efectiva').toString() + '.'
      this.createCampaignModalData.description.automatic = this.$t('Programa tus campañas y deja que 360NRS las envíe por ti').toString() + '.'
      this.createCampaignModalData.description.meet = this.$t('Reduce las ausencias con notificaciones eficientes y atractivas a través de los mensajes RCS').toString() + '.'
      this.createCampaignModalData.description.birthday = this.$t('Automatiza tus mensajes de aniversario con contenido personalizado y dinámico').toString() + '.'
      this.createCampaignModalData.icon = 'fa-paper-plane'
      this.createCampaignModalData.image = require('@/assets/img/icons/sms.svg')
      this.createCampaignModalData.helpImage = require('@/assets/img/campaign-examples/' + localeIso + '/basic-sms.png')
      this.createCampaignModalData.imageEnriched = require('@/assets/img/icons/rocket-solid.svg')
      this.createCampaignModalData.helpImageEnriched = require('@/assets/img/campaign-examples/' + localeIso + '/basic-landing.png')
      this.createCampaignModalData.helpImageBirthday = require('@/assets/img/campaign-examples/' + localeIso + '/basic-sms-birthday.png')
      this.createCampaignModalData.helpImageMeet = require('@/assets/img/campaign-examples/' + localeIso + '/basic-sms-meet.png')
      this.createCampaignModalData.iconResourceType = 'image'
      this.createCampaignModalData.routes = this.routes.campaigns.rcs
      this.createCampaignModalData.show = true
    },
    goToStats (channel) {
      this.$router.push('/stats/' + channel)
    },
    getTabIdByName (list, name) {
      const found = list.filter((item) => {
        return item.name === name
      })
      return found.length > 0 ? found[0].id : 0
    },
    subActionActive (firstLevel, secondLevel, thirdLevel, containerLevel = false) {
      const segments = this.getUrlSegments()
      if (containerLevel) {
        return segments[1] === firstLevel && secondLevel.includes(segments[2]) && thirdLevel.includes(segments[3])
      }

      return segments[1] === firstLevel && segments[2] === secondLevel && segments[3] === thirdLevel
    },
    isHome () {
      const segments = this.getUrlSegments()
      return segments[1] === 'home'
    },
    getUrlSegments () {
      return this.$route.path.split('/')
    },
    goToWhiteBook () {
      window.open(this.urlWhiteBook, '_blank')
    },
    goToManualRgpd () {
      window.open(this.urlRgpd, '_blank')
    },
    validateSectionsBlockeds (section) {
      if (this.sectionsBlockeds.includes(section)) {
        return false
      }
      return true
    },
    getUserLanguage () {
      AccountService.getUserLanguage()
        .then((response) => {
          const languageStored = localStorage.getItem('language')
          if (languageStored) {
            this.userLanguage = languageStored
            this.$i18n.locale = languageStored
          } else {
            const isoLang = response.iso
            const langConfigured = this.languages.find(language => language.iso === isoLang)
            this.userLanguage = langConfigured?.iso || Languages.defaultLanguageIso
            this.$i18n.locale = langConfigured?.iso || Languages.defaultLanguageIso
          }
          ValidatorLocalize.setLanguage(this.userLanguage)

          if (response.newsData) {
            this.newsDropDown = response.newsData.news
            this.newsCounter = response.newsData.count
          }
        })
    },

    redirectTo (path) {
      if (path.startsWith('http')) {
        return window.open(path)
      }
      this.$router.push(path)
    },
    getUserCountry () {
      const user = this.$store.getters['auth/getUser']
      if (user) {
        if (!user.isoCountry) {
          this.$store.dispatch('auth/setUserCountry')
        }
      }
    },
    setBalanceAlert () {
      AccountService.getBalance().then((data) => {
        this.balanceAlert = data
      })
    },
  },
}
