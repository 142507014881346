
export class TextUtils {
  static titleCase (str) {
    return str.toLowerCase().split(' ').map(function (word) {
      return word.replace(word[0], word[0].toUpperCase())
    }).join(' ')
  }

  static formatTextIfEmojiAndFormatPresent (text) {
    const formats = ['_', '*', '~']
    const regex = new RegExp(`([\\p{Emoji_Presentation}\\p{Emoji}\\uFE0F])([${formats.join('')}])(.*)\\2`, 'gu')
    if (!regex.test(text)) {
      return false
    }
    return text.replace(regex, '$1 $2$3$2')
  }
}
