import HeadboardType from '@/models/whatsapp-editor/HeadboardType.js'
import HeadBoardTypeAuth from '@/models/whatsapp-editor/HeadBoardTypeAuth.js'
import { CALL_TO_ACTION } from './enums/CallToAction'
import { AUTOMATIC_RESPONSE } from './enums/AutomaticResponse'

export default class WhatsappEditor {
  constructor () {
    this.id = null
    this.templateWhatsappCategoryId = null
    this.templateWhatsappLanguageId = null
    this.templateWhatsappStatusId = null
    this.name = ''
    this.type = null
    this.message = ''
    this.headboardType = HeadboardType.headboardTypes
    this.selectedHeadboardType = 1
    this.headboard = ''
    this.headboardOptions = [
      { value: 1, text: 'Imagen' },
      { value: 2, text: 'Texto' },
      { value: 3, text: 'Video' },
      { value: 4, text: 'Archivo adjunto' },
      { value: 5, text: 'Localización' },
    ]
    this.callToActionOptions = CALL_TO_ACTION
    this.automaticResponseOptions = AUTOMATIC_RESPONSE
    this.selectedHeadboardOption = 1
    this.footerPage = ''
    this.callActionUrlText = 'Abrir enlace'
    this.callActionUrl = 'https://example.com'
    this.callActionUrlActive = true
    this.callActionPhoneText = 'Llamar teléfono'
    this.callActionPhone = ''
    this.callActionPhonePrefix = ''
    this.callActionCountryPrefix = 'ES'
    this.callActionPhoneActive = false
    this.selectedCallToActionOption = 1
    this.automaticResponseColOptions = [
      { value: 1, text: '100%' },
      { value: 2, text: '50%' },
    ]
    this.callToActionResponses = []
    this.automaticResponses = []
    this.statusTemplate = {
      APPROVED: 0,
      PENDING: 2,
      REJECTED: 3,
      PAUSED: 7,
      DRAFT: 9,
      INCORRECT_FORMAT: 12,
    }
    this.status = 1
    this.path = 'whatsapp'
    this.HeadBoardTypeAuth = new HeadBoardTypeAuth()
    this.whatsappWabaId = null
    this.customFieldsExampleValues = {
      header: '',
      body: [],
      button: [],
    }
  }

  load (whatsappEditor) {
    console.log('whatsappEditor: ', whatsappEditor)
    this.id = whatsappEditor.id
    this.templateWhatsappCategoryId = whatsappEditor.template_whatsapp_category_id
    this.templateWhatsappLanguageId = whatsappEditor.template_whatsapp_language_id
    this.templateWhatsappStatusId = whatsappEditor.template_whatsapp_status_id
    this.name = whatsappEditor.name
    this.message = whatsappEditor.message
    this.selectedHeadboardType = whatsappEditor.head_board_type
    this.selectedHeadboardOption = whatsappEditor.head_board_option
    this.headboard = JSON.parse(whatsappEditor.head_board)
    this.footerPage = whatsappEditor.footer_page
    this.callActionUrlText = whatsappEditor.call_action_url_text
    this.callActionUrl = whatsappEditor.call_action_url
    this.callActionUrlActive = whatsappEditor.call_action_url_active
    this.callActionPhoneText = whatsappEditor.call_action_phone_text
    this.callActionPhone = whatsappEditor.call_action_phone
    this.callActionCountryPrefix = whatsappEditor.call_action_country_prefix
    this.callActionPhoneActive = whatsappEditor.call_action_phone_active
    this.automaticResponses = JSON.parse(whatsappEditor.automatic_responses)
    this.callToActionResponses = JSON.parse(whatsappEditor.call_to_action_responses)
    this.HeadBoardTypeAuth.loadData(JSON.parse(whatsappEditor.head_board_type_auth ?? 0))
    this.status = whatsappEditor.status
    this.path = whatsappEditor.path
    this.whatsappWabaId = whatsappEditor.whatsapp_waba_id
    // re-create data structure with {COL+} positions and values coming from DB
    this.customFieldsExampleValues = this.setCustomFieldExamples(whatsappEditor.custom_fields_example_values)

    if (!Array.isArray(this.customFieldsExampleValues.button)) {
      this.customFieldsExampleValues.button = this.customFieldsExampleValues.button.length
        ? [this.customFieldsExampleValues.button]
        : []
    }
  }

  setCustomFieldExamples (exampleValues) {
    const regex = new RegExp('\\{COL\\+\\}', 'g')
    const matches = [...this.message.matchAll(regex)]

    const customFieldExamples = exampleValues
      ? JSON.parse(exampleValues)
      : this.customFieldsExampleValues

    customFieldExamples.body = customFieldExamples.body.map((value, idx) => ({
      position: matches[idx].index,
      value,
    }))

    return customFieldExamples
  }

  getWhatsappEditor () {
    return {
      template_whatsapp_category_id: this.templateWhatsappCategoryId,
      template_whatsapp_language_id: this.templateWhatsappLanguageId,
      template_whatsapp_status_id: this.templateWhatsappStatusId,
      name: this.name,
      message: this.message,
      head_board_type: this.selectedHeadboardType,
      head_board: JSON.stringify(this.headboard),
      head_board_option: this.selectedHeadboardOption,
      footer_page: this.footerPage,
      call_action_url_text: this.callActionUrlText,
      call_action_url: this.callActionUrl,
      call_action_url_active: this.callActionUrlActive,
      call_action_phone_text: this.callActionPhoneText,
      call_action_phone: this.callActionPhone,
      call_action_country_prefix: this.callActionCountryPrefix,
      call_action_phone_active: this.callActionPhoneActive,
      automatic_responses: this.automaticResponses,
      call_to_action_responses: this.callToActionResponses,
      status: this.status,
      path: this.path,
      head_board_type_auth: this.HeadBoardTypeAuth,
      whatsapp_waba_id: this.whatsappWabaId,
      custom_fields_example_values: JSON.stringify({
        header: this.customFieldsExampleValues.header,
        body: this.customFieldsExampleValues.body.map(bodyVar => bodyVar.value),
        button: this.customFieldsExampleValues.button,
      }),
    }
  }

  getWhatsappEditorForSave () {
    return {
      id: this.id,
      template_whatsapp_category_id: this.templateWhatsappCategoryId,
      template_whatsapp_language_id: this.templateWhatsappLanguageId,
      template_whatsapp_status_id: this.templateWhatsappStatusId,
      name: this.name,
      message: this.message,
      head_board_type: this.selectedHeadboardType,
      head_board: JSON.stringify(this.headboard),
      head_board_option: this.selectedHeadboardOption,
      footer_page: this.footerPage,
      call_action_url_text: this.callActionUrlText,
      call_action_url: this.callActionUrl,
      call_action_url_active: this.callActionUrlActive,
      call_action_phone_text: this.callActionPhoneText,
      call_action_phone: this.callActionPhone,
      call_action_country_prefix: this.callActionCountryPrefix,
      call_action_phone_active: this.callActionPhoneActive,
      automatic_responses: this.automaticResponses,
      call_to_action_responses: this.callToActionResponses,
      status: this.status,
      path: this.path,
      head_board_type_auth: this.HeadBoardTypeAuth,
      whatsapp_waba_id: this.whatsappWabaId,
      custom_fields_example_values: JSON.stringify(this.customFieldsExampleValues),
    }
  }

  clearEditorFromAuth () {
    this.message = ''
    this.footerPage = ''
    this.headboard = ''
  }
}
